import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Button from "@material-ui/core/Button"; // This only needs to be imported once in your app


export default class DiagnosisPlot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div>
        <Button  variant="outlined" onClick={() => this.setState({ isOpen: true })}>
          Diagnosis Plot
        </Button>

        {isOpen && (
          <Lightbox
            mainSrc={`https://blickm.pandonia-global-network.org/plots/tsview_${this.props.location}_${this.props.panID}.png`}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </div>
    );
  }
}
