import React from 'react';


const extractTimes = (data) => {
    return data.map(item => {
        const date = new Date(item.time);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        return `${year}/${month}/${day} ${hours}:${minutes}:${seconds} UTC`;
    });
}


const LiveStatusDetail = (props) => {
    const times = extractTimes(props.data);

    return (
        <div>
            <h3>L0 files handled in the last 30 minutes:</h3>
            <ul>
                {times.map((time, index) => (
                    <li key={index}>{time}</li>
                ))}
            </ul>
        </div>
    );
}


export default LiveStatusDetail;
